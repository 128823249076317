import { IUser, requestType } from '../../../../../store/services/requestApi/requestTypes';

export interface IRequestValues {
  description: string;
  type: requestType;
  username: string;
  email: string;
}

export const getRequestInitialValues = (
): IRequestValues => ({
  description:'',
  type: 'REQUEST',
  username: '',
  email: ''
});
