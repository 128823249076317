import React, { useEffect, useMemo, useState, lazy, Suspense } from 'react';
import { IDocument, ITagData } from 'src/store/services/tagsApi/tagTypes';
import ExternalLinks from 'src/views/Tag/components/TagContent/components/ExternalLinks';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import EventsTable from '../../../Events/components/EventsTable';
import TagQRCode from './components/TagQRCode';
import TagWarranty from './components/TagWarranty';
import ImageNone from 'src/assets/no_img.jpg';
import ImageGallery from './components/AuthenticatedTagInfo/components/ImageGallery';
import LoadDocuments from './components/AuthenticatedTagInfo/components/DocumentUploader/LoadDocuments';
import CustomAccordion from '../../../../components/CustomAccordion';
import TagDetails, { ITagStates } from './components/TagDetails';
import TagCategories from './components/TagCategories';
import { useGetCategoriesQuery } from '../../../../store/services/categoryApi';
import { getLinkedProperties } from '../../../../helpers/categoriesOptions';
import AuthenticatedTagInfo from './components/AuthenticatedTagInfo';
import ServiceAndMaintenance from '../../../../components/ServiceAndMaintenance';
import { JwtDecodeUsers } from '../../../../helpers/JwtDecodeUsers';
import { useAppSelector } from '../../../../helpers/reduxCommon';
import ButtonWithIcon from '../../../../components/ButtonWithIcon';
import { ReactComponent as IconEdit } from 'src/assets/EditItem.svg';
import EditTagModal from '../../../../components/Modals/Tag/EditTagModal';
import ChipWithIcon from 'src/components/ChipWithIcon';
import {getStateIcon, getStateStyle} from 'src/components/AssetsTable/components/AssetTableRow'

import '../../../../../src/MarkdownStyles.css'; 

const ReactMarkdown = lazy(() => import('react-markdown'));

interface ITagContentProps {
  data: ITagData;
}

const TagContent = ({ data }: ITagContentProps) => {
  
  const { t } = useTranslation('tag');
  const { isAuthenticated } = useAuth0();
  const token = useAppSelector((state) => state.auth.token);

  const isAssetOwner = isAuthenticated && !!JwtDecodeUsers(token).find(
    (organization) => organization.value === data?.owner.name,
  );
  const [tagImages, setTagImages] = useState<IDocument[] | []>([]);
  const [tagDocuments, setTagDocuments] = useState<IDocument[] | []>([]);
  const [isLatestAccordionOpen, setIsLatestAccordionOpen] = useState<boolean>(
    window.innerWidth >= 768,
  );
  const [isDocumentsAccordionOpen, setIsDocumentsAccordionOpen] = useState<boolean>(
    window.innerWidth >= 768,
  );
 
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const [gfm, setGfm] = useState<any>(null);

  const { data: categoryList, isSuccess: isCategoriesSuccess } = useGetCategoriesQuery({});

  const linkedProperties = useMemo(() => {
    return getLinkedProperties(
      [...(data.categories || []), ...(data.connectedCategories || [])],
      data.properties || {},
      categoryList?.categoryTypes,
    );
  }, [categoryList?.categoryTypes, data.categories, data.connectedCategories, data.properties]);


  useEffect(() => {
    const loadGfm = async () => {
      const gfmModule = await import('remark-gfm');
      setGfm(() => gfmModule.default);
    };
    loadGfm();
  }, []);

  useEffect(() => {
    const { tagDocuments, tagImages } = data.documents.reduce(
      (acc: { tagDocuments: IDocument[]; tagImages: IDocument[] }, file: IDocument) => {
        if (file.documentType === 'IMAGE') {
          acc.tagImages.push(file);
        } else {
          acc.tagDocuments.push(file);
        }
        return acc;
      },
      { tagDocuments: [], tagImages: [] },
    );
    setTagImages(tagImages);
    setTagDocuments(tagDocuments);
  }, [data.documents]);

  const tagUrl = "https://t.sirk.app/" + data.tag;

  if (!gfm) return null; 

  return (
      <div className="flex flex-col w-full bg-white gap-16">
        <img
          src={tagImages[0]?.url || ImageNone}
          alt="asset hero"
          className="fixed top-0 left-0 w-full h-[45vh] xl:h-full object-cover xl:static z-10"
        />
        <div
          className="absolute top-[45vh] left-0 xl:static w-full pb-14 bg-white z-20 font-tag-font-family text-tag-font-color text-tag-font-size"
          id="asset-content"
        >
          {!!tagImages.length && (
            <section className="w-full mt-4 md:px-8">
              <ImageGallery assetImages={tagImages} />
            </section>
          )}
          <section className="flex w-full justify-between pt-2 md:gap-10 px-2 md:px-5 bg-logo-image bg-logo-position bg-logo-size bg-no-repeat">
            <div className="w-full h-full">
              <h2 className="text-2xl font-tag-font-family font-bold text-tag-font-color tracking-tight my-3 ">
                {data.name}
              </h2>
              <div className="markdown-editor">
                <Suspense fallback={<div>Loading markdown...</div>}>
                  <ReactMarkdown remarkPlugins={[gfm]}>
                    {data.description}
                  </ReactMarkdown>
                </Suspense>
              </div>

              <section className="flex justify-start items-center flex-wrap md:my-10 md:py-10 gap-3">
                <TagQRCode tag={data.tag} url={tagUrl} >
                </TagQRCode>

                <ExternalLinks externalIds={data?.externalIds} />
                {data.warranty && <TagWarranty expirationDate={data.warranty.expiration} />} 
                <ChipWithIcon icon={getStateIcon(data?.state, "w-5 h-5 ")} text={t(`asset-state-${data?.state?.toLowerCase()}-option`)}   className={"rounded shadow-xl w-32  py-12 px-1  my-2 text-[1.2em]" + getStateStyle(data?.state)} />
                
              <AuthenticatedTagInfo
                  location={data?.location || undefined}
                  batches={data?.batches || undefined}
                  isAuthenticated={isAuthenticated}
                  state={data.state as ITagStates}
                />
              <TagCategories
                properties={data?.properties || {}}
                selectedCategories={data?.categories}
                connectedCategories={data.connectedCategories}
                categoryList={categoryList}
                isCategoriesSuccess={isCategoriesSuccess}
                />

                
              </section>

              <section className="md:grid md:grid-cols-2  md:gap-4 2xl:gap-10 md:my-5 md:py-5">

                
              <TagDetails
                asset={data}
                properties={data?.properties || {}}
                selectedCategories={data?.categories}
                connectedCategories={data.connectedCategories}
                categoryList={categoryList}
                isCategoriesSuccess={isCategoriesSuccess}
              />
              {tagDocuments.length  ? (
                <CustomAccordion
                  isAccordionOpen={isDocumentsAccordionOpen}
                  setIsAccordionOpen={setIsDocumentsAccordionOpen}
                  title={t('documents-and-links-title')}
                  accordionWrapperClassName="font-tag-font-family text-tag-font-color"
                >
                  <LoadDocuments
                    tagDocuments={tagDocuments}
                  />
                </CustomAccordion>
              ) : null}
            
            <ServiceAndMaintenance 
                isOpen={window.innerWidth >= 768}
                asset={data}
                properties={data?.properties || {}}
                defaultServiceRequestUrl={data?.defaultManualRequestUrl}
            />

              {isAuthenticated && data?.id && (
        
                <CustomAccordion
                  isAccordionOpen={isLatestAccordionOpen}
                  setIsAccordionOpen={setIsLatestAccordionOpen}
                  title={t('latest-changes')}
                  accordionWrapperClassName="pb-16 font-tag-font-family text-tag-font-color"
                >
                  <EventsTable
                    defaultId={String(data?.id)}
                    defaultType={'ASSET'}
                    pageSize={5}
                    rowClassNames="font-tag-font-family text-tag-font-color"
                    displayType={false}
                  />
                </CustomAccordion>
              )}
              
             </section>
            </div>
          </section>
          {isAssetOwner && (
                 <ButtonWithIcon
                      className="flex items-center justify-center gap-1 py-[10px] px-5   rounded text-center text-tag border-2 border-solid border-tag transition-colors duration-300 ease-linear m-2"
                      icon={<IconEdit className="w-6 h-6 fill-tag" />}
                      text={t('edit_tag')}
                      onClick={() => setIsModalOpened(true)}
                 />
              )}
        </div>
        <EditTagModal
          isOpened={isModalOpened}
          onClose={() => setIsModalOpened(false)}
          data={data}
      />
      </div>
  );
};

export default React.memo(TagContent);
