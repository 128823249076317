import React from 'react';
import { useTranslation } from 'react-i18next';  
import { ReactComponent as SendIcon } from '../../src/assets/IconSend.svg';

interface ISendButton {
  submitFunc: () => void;
  disabled?: boolean;
}

const SendButton = ({ 
  submitFunc, 
  disabled = false
 }: ISendButton) => {
  const { t } = useTranslation('common');  

  return (
    <button
      type="button"
      onClick={() => {submitFunc();}}
        disabled={disabled}
        className="flex items-center justify-center gap-1 w-[160px] py-[10px] h-[52px] rounded text-lg leading-normal text-center text-white bg-green-600 border-2 border-solid border-green-600 transition-colors duration-300 ease-linear cursor-pointer disabled:bg-[#aaa6a5] disabled:cursor-default"
      >
      <SendIcon className="w-6 h-6 fill-white" />
      <span>{t('form-button-send')}</span>
    </button>
  );
};

export default SendButton;
