import React, { ReactElement } from 'react';

interface IButtonWithIconProps {
  icon: ReactElement;
  text: string;
  onClick: () => void;
  className: string;
  disabled?: boolean;
}

const ButtonWithIcon = ({ icon, text, onClick, className, disabled = false }: IButtonWithIconProps) => {
  return (
    <button type="button" onClick={onClick} className={className} disabled={disabled}
>
      {icon}
      <p>{text}</p>
    </button>
  );
};

export default ButtonWithIcon;
