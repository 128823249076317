import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';

import ServiceRequestModal from 'src/components/Modals/ServiceRequest/ServiceRequestModal';
import ButtonWithIcon from 'src/components/ButtonWithIcon';
import { ReactComponent as IconSend } from 'src/assets/IconSend.svg';
import { ITagData } from 'src/store/services/tagsApi/tagTypes';

interface ICategoryRow {
  propertyName: string;
  propertyValue?: string;
  category?: string;
  classNames?: string;
  asset?: ITagData;
}

const CategoryRow = ({ propertyName, propertyValue, classNames, category, asset }: ICategoryRow) => {
  const { t } = useTranslation('tag');
  const extractDomain = (url: string) => { try { return new URL(url).hostname; } catch (e) { return ""; }}

  const hasLink = propertyValue
    ? propertyValue.startsWith('http://') || propertyValue.startsWith('https://')
    : false;

  const hasEmailForm = propertyName
  ? propertyName === "orderServiceProgram"
  : false;
    
  console.log("hasEmailForm", hasEmailForm);
  console.log("propertyValue", propertyValue);
  console.log("propertyName", propertyName);
  const extractedLink = hasLink ? extractDomain(propertyValue as string) : "";
     
  const [isRequestModalOpen, setIsRequestModalOpen] = useState<boolean>(false);
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

  return (
   
      propertyValue ? (
    <tr className={`font-['Mukta_Mahee',_Arial,_sans-serif] cursor-pointer even:bg-gray-200 ${classNames}`}> 
      <td className="border border-solid border-[#eee] text-base p-[6px] text-left relative">
        {t(`table-property-${propertyName}`, { defaultValue: propertyName })}
      </td>
        <td className="border border-solid border-[#eee] text-base p-[6px] text-left relative">
          {hasEmailForm && asset ? (
                    <>
                  <ButtonWithIcon
                    className={`flex items-center justify-center gap-1 py-[5px] px-2  my-1 rounded text-center  border-2  ${
                      !isEmailSent ? 'border-solid border-black text-black' : 'border-none border-gray-300 text-gray italic'
                        }`}
                   icon={<IconSend className={`w-6 h-6  ${!isEmailSent ? 'fill-black' : 'fill-[#999]' }`} />}
                   text={!isEmailSent ? t('service-send-request') : t('service-request-sent')}
                   disabled={isEmailSent}
                   onClick={() => {
                     setIsRequestModalOpen(true);
                   }
                 }
               
                 />
                   
                   <ServiceRequestModal
                     asset={asset}
                     serviceProvider={category ? category : propertyValue}
                     isOpened={isRequestModalOpen}
                     onSuccess={() => setIsEmailSent(true)}
                     onClose={() => setIsRequestModalOpen(false)}
                   />
                   </>
          ) : hasEmailForm ?  (
            <a href={propertyValue} target="_blank" rel="noreferrer">
            {extractedLink}
          </a>
          ) : (
            propertyValue
          )}
        </td>
  </tr>
      ) : null
  );
};

export default CategoryRow;
