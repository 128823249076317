import { apiRtk } from '../index';
import { REQUEST } from '../../storeTypes';
import { BaseQueryMeta } from '@reduxjs/toolkit/src/query/baseQueryTypes';
import { IRequestData } from './requestTypes';

export const requestApi = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    request: build.mutation({
      query: ({ body, headers }: { body: IRequestData; headers?: Record<string, string> }) => ({
        url: '/request',
        method: REQUEST.POST,
        body,
        ...(headers ? { headers } : {}),
      }),
      transformErrorResponse: (error: any, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
      },
    }),
  }),
});

export const { useRequestMutation } = requestApi;