import React, { useEffect, useCallback } from 'react';
import { FormikProps, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import FormField from 'src/components/FormField';
import ModalTitle from 'src/components/Modals/components/ModalTitle';
import FormWrapper from 'src/components/FormWrapper';
import ModalContainer from 'src/components/Modals/components/ModalContainer';
import ServerFormError from 'src/components/ServerFormError';
import { ServerError } from 'src/store/storeTypes';
import SendButton from 'src/components/SendButton';
import CloseButton from 'src/components/CloseButton'; 
import { getRequestInitialValues, IRequestValues } from './getRequestInitialValues';
import { RequestValidation } from './requestValidation';
import { ITagData } from 'src/store/services/tagsApi/tagTypes';

interface ISendServiceRequestForm {
    onSubmit: (values: any) => void;
    title: string;
    isSuccess: boolean;
    isLoading: boolean;
    isError: boolean;
    error: any;
    isOpened: boolean;
    asset: ITagData;
    serviceProvider: string;
    onClose: () => void;
}


const SendServiceReqeustForm = ({
    asset,
    serviceProvider,
    title,
    isOpened,
    onClose,
    onSubmit,
    isSuccess,
    isLoading,
    isError,
    error,
  }: ISendServiceRequestForm) => {
    const { t } = useTranslation('service');
  
    const initialValues = getRequestInitialValues();
    
    const {
      values,
      handleChange,
      handleSubmit,
      errors,
      touched,
      setFieldValue,
      resetForm,
    }: FormikProps<IRequestValues> = useFormik<IRequestValues>({
      onSubmit: (values) => {
        onSubmit({
          id: asset.id,
          description: values.description,
          type: values.type,
          requestedBy: {
            name: values.username,
            email: values.email,
          },
          serviceProvider: serviceProvider.toLowerCase(),
        }
        );
        resetForm();
      },
      initialValues,
      validationSchema: RequestValidation(),
    });
  
    const handleClose = useCallback(() => {
      resetForm();
      onClose();
    }, [resetForm, onClose]);
  
    useEffect(() => {
      if (isSuccess) {
        onClose();
      }

    }, [isSuccess, onClose]);
    return (
      <ModalContainer isModalOpened={isOpened} onClose={handleClose} isFormModal={true}>
        <ModalTitle title={title} />
        

        <FormWrapper onSubmit={handleSubmit}>
          <p className="py-10 text-center">{t("send-request-explanation", {"provider": serviceProvider.replace("mailto:", ""), "asset": asset.tag ? asset.tag : asset.name})}</p>

          <FormField
            type="text"
            value={values.username}
            name={'username'}
            placeholder={t('request-username')}
            onChange={handleChange}
            isError={!!errors.username && !!touched.username}
            errorMessage={(touched.username && errors?.username) || ''}
          />
          <FormField
            type="text"
            value={values.email}
            name={'email'}
            placeholder={t('request-email')}
            onChange={handleChange}
            isError={!!errors.email && !!touched.email}
            errorMessage={(touched.email && errors?.email) || ''}
          />
         <FormField
            as="textarea"
            value={values.description}
            name={'description'}
            placeholder={t('request-description')}
            onChange={handleChange}
            isError={!!errors.description && !!touched.description}
            errorMessage={(touched.description && errors?.description) || ''}
          />
  
          <div className="flex justify-center mt-4 pb-10 mb-20">
            <SendButton submitFunc={handleSubmit} disabled={isLoading} />
            <CloseButton onClose={handleClose} disabled={isLoading} />
          </div>
  
         
  
          <ServerFormError
            title={t('error-request')}
            requestError={error as ServerError}
            isError={isError}
          />
        </FormWrapper>
      </ModalContainer>
    );
  };
  
  export default React.memo(SendServiceReqeustForm);
  