import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRequestMutation } from 'src/store/services/requestApi';
import SendServiceRequestForm from './components/ServiceRequestForm/ServiceRequestForm';
import { IAsset } from 'src/store/services/assetsApi/assetsTypes';
import { ITagData } from 'src/store/services/tagsApi/tagTypes';

interface IServiceRequestModal {
  asset: ITagData;
  serviceProvider: string;
  onClose: () => void;
  onSuccess: () => void;
  isOpened: boolean;
}

const ServiceRequestModal: React.FC<IServiceRequestModal> = ({ 
  asset,
  serviceProvider,
  isOpened, 
  onClose,
  onSuccess
}) => {
  const { t } = useTranslation('service');
  const [request, { isSuccess, isLoading, isError, error }] = useRequestMutation();


  const handleSubmit = async (values: any) => {
    console.log(values);
    console.log("DON#");
    try {
      await request({body: values}).unwrap();
      onSuccess();
      onClose();

    } catch (error) {
      console.error('Failed to send request:', error);
    }
  };

  return (
    <SendServiceRequestForm
      title={t('service-send-request')}
      asset={asset}
      serviceProvider={serviceProvider}
      onSubmit={handleSubmit}
      isSuccess={isSuccess}
      isLoading={isLoading}
      isError={isError}
      error={error}
      isOpened={isOpened}
      onClose={onClose}
    />
  );
};

export default ServiceRequestModal;