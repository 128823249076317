import React, { ReactNode } from 'react';
import { ReactComponent as IconExpand } from 'src/assets/IconExpand.svg';

interface ICustomAccordion {
  isAccordionOpen: boolean;
  setIsAccordionOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  accordionWrapperClassName?: string;
  contentWrapperClassName?: string;
  bodyClassName?: string;
  headerClassName?: string,
  children: ReactNode;
  style?: React.CSSProperties;
  icon?: React.ReactNode;
}

const CustomAccordion = ({
  isAccordionOpen,
  setIsAccordionOpen,
  title,
  accordionWrapperClassName,
  contentWrapperClassName,
  bodyClassName,
  headerClassName,
  children,
  style,  
  icon
}: ICustomAccordion) => {
  const toggleAccordion = () => {
    setIsAccordionOpen((prev) => !prev);
  };

  return (
    <div
      id="accordion-collapse"
      data-accordion="collapse"
      className={` pb-4 md:pb-0  ${accordionWrapperClassName}`}
      style={style} 
    >
      <button
        type="button"
        className={"flex items-center justify-between w-full p-2 font-medium rounded-t gap-3 border " + ` ${headerClassName ?  headerClassName : ' border-gray-100 bg-gray-100 focus:bg-gray-200 hover:bg-gray-200' } ` }
        data-accordion-target="#accordion-collapse-body-1"
        aria-expanded={isAccordionOpen}
        aria-controls="accordion-collapse-body-1"
        onClick={toggleAccordion}
      >
        <div className="flex justify-between items-center">
          <h2
            id="accordion-collapse-heading-1"
            className="text-[18.72px] font-bold inline-flex"
          >
            {icon}
            {title}
          </h2>
        </div>
        <IconExpand className={`w-6 h-6 shrink-0 ${isAccordionOpen ? 'rotate-180' : ''}`} />
      </button>
      <div
        id="accordion-collapse-body-1"
        className={ `${bodyClassName ? bodyClassName : ' bg-gray-100  '} ${isAccordionOpen ? 'block' : 'hidden'}`}
        aria-labelledby="accordion-collapse-heading-1"
      >
        {children && (
          <div
            className={` p-1   ${contentWrapperClassName}`}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomAccordion;
