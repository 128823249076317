import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export const RequestValidation = () => {
  const { t } = useTranslation('service');
  return yup.object().shape({
    username: yup.string().required(t('required-username')),
    email: yup.string().email().required(t('required-email')),
    description: yup.string().required(t('required-description')),
  });
};
